import React, { useEffect } from "react";
import { Typewriter } from "../comp/Typewritter";
import { SetDocumentTitleAndMeta, Url } from "../Utils";
import { Row, Col, Button, Card } from "react-bootstrap";
import "aos/dist/aos.css"; // Import AOS styles
import AOS from "aos"; // Import AOS for animations
import StandardContent from "../comp/StandardContent";
import "./Landing.scss";
import LandingImg1 from "../images/image.png";
import LandingImg2 from "../images/landing2.png";
import LandingImg3 from "../images/landing3.jpg";
import LandingImg5 from "../images/landing5.png";
import LandingImg6 from "../images/landing6.png";
import VideoPoster from "../images/video-poster.png";

const Landing = () => {
    useEffect(() => {
        AOS.init({ duration: 1500 });
    }, []);
    useEffect(() => SetDocumentTitleAndMeta("landing"));

    return (
        <div className="landing-page">
            <div className="page-top-bg">
                <div className="dotted-bg"></div>
            </div>
            <StandardContent className="container">
                <Row className="align-items-top my-5 first" data-aos="fade-up">
                    <Col md={6} className="pb-5">
                        <span class="badge rounded-pill bg-light text-dark">
                            <Typewriter text="Powered by Artificial Intelligence" />
                        </span>
                        <h1 className="mt-2 pb-2 pt-2">Transform Quality Assurance</h1>
                        <p className="lead">
                            Meet the All-in-One Quality Assurance Automation Platform. Release
                            faster and ship high-quality user interface with Quaind’s flagship test designer and fully
                            automated workflows.
                        </p>
                        <div>
                            <Button
                                className="me-2 btn-lg"
                                variant="outline-secondary"
                                href={"#our-product"}
                            >
                                Learn more
                            </Button>
                            <Button
                                className="btn-lg"
                                variant="primary"
                                href={Url("GetDemo")}
                            >
                                Get a demo
                            </Button>
                        </div>
                    </Col>
                    <Col md={6} data-aos="fade-left">
                        <img
                            src={LandingImg1}
                            alt="Meet Quaind, the All-in-One quality assurance automation platform."
                            className="img-fluid rounded"
                        />
                    </Col>
                </Row>
                <Row className="align-items-center my-5 pb-5" data-aos="fade-up">
                    <Col md={6} data-aos="fade-right" className="order-2 order-md-1">
                        <img
                            src={LandingImg2}
                            alt="With Quaind you can release faster with confidence."
                            className="img-fluid rounded"
                        />
                    </Col>
                    <Col md={6} className="pb-5 order-1 order-md-2">
                        <h2 className="pb-2">Release Faster with Confidence</h2>
                        <p className="lead">
                            Software testing is a crucial step and it often demands a substantial amount of
                            resources. Quaind changes the game with fully automated, no-code
                            workflows that empower teams to complete test cycles faster. With
                            just a few clicks, you can streamline the entire UI quality
                            assurance process, ensuring your product moves from testing to
                            release faster—without compromising quality. <br />
                            <br />
                            Our 24/7 continuous testing ensures you catch visual regressions
                            early and respond swiftly. With real-time monitoring and instant
                            alerts, you can address issues before they impact your users. Stay
                            ahead of your competition by leveraging Quaind's cutting-edge AI-powered
                            technology to maintain a flawless user experience, reduce
                            customer churn, and release confidently—every time.
                        </p>
                        <div>
                            <Button
                                className="me-2 btn-lg"
                                variant="primary"
                                href={"#our-product"}
                            >
                                Read more
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row className="align-items-center my-5" data-aos="fade-up">
                    <Col md={6} className="pb-5">
                        <h2 className="pb-2">
                            Deliver a High-Quality User Interface, Every Time
                        </h2>
                        <p className="lead">
                            Bugs in the user interface can significantly impact customer satisfaction.
                            Leveraging AI, Quaind can perform continuous quality assurance
                            tests and conduct in-depth visual and contextual analysis of your
                            website. Our platform is capable of detecting and alerting
                            problems before they even reach production.
                            <br />
                            <br />
                            Take advantage of our fully automated, no-code quality assurance
                            workflows. Ship a bug-free UI to your end users and boost customer
                            satisfaction.
                        </p>
                        <div>
                            <Button
                                className="me-2 btn-lg"
                                variant="outline-primary"
                                href={"#our-product"}
                            >
                                Learn more
                            </Button>
                            <Button
                                className="me-2 btn-lg"
                                variant="primary"
                                href={Url("GetDemo")}
                            >
                                Schedule demo
                            </Button>
                        </div>
                    </Col>
                    <Col md={6} data-aos="fade-left" className="pb-5">
                        <img
                            src={LandingImg3}
                            alt="Deliver a High-Quality User Interface, Every Time."
                            className="img-fluid rounded"
                        />
                    </Col>
                </Row>
            </StandardContent>

            <div id="our-product" className="align-items-center mt-5 pt-5 pb-1 designer-row">

                <StandardContent>
                    <Row>
                        <Col md={2}></Col>
                        <Col md={8}>
                            <h2 className="text-center">
                                Meet the Interactive Test Designer
                            </h2>
                            <p className="text-center lead">
                                Our feature-rich interactive test designer makes it possible to create a test plan with just a few clicks. <br />Capture the interactions with your website to craft test plans.
                            </p>
                            <video controls className="w-100" poster={VideoPoster}>
                                <source src="https://quaind.com/quaind-demo.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </Col>
                    </Row>
                </StandardContent>
            </div>
            <StandardContent>
                <Row>
                    <Col md={2}></Col>
                    <Col md={8}>
                        <h2 className="text-center">
                            Record & Reply With Ease
                        </h2>
                        <p className="text-center lead">
                            Scatch an automated test plan with just a few clicks.<br />
                            Make them mimic customer interactions.
                        </p>
                        <img
                            data-aos="zoom-in"
                            src={LandingImg5}
                            alt="Record & Reply With Ease"
                            className="img-fluid"
                        />
                    </Col>
                </Row>
            </StandardContent>

            <StandardContent>
                <Row>
                    <Col md={2}></Col>
                    <Col md={8}>
                        <h2 className="text-center">
                            Effortlessly Schedule and Run Your Tests
                        </h2>
                        <p className="text-center lead">
                            Offload test execution to our pipelines, which run your tests 24/7.<br />
                            Get instant alerts when your tests fail.
                        </p>
                        <img
                            data-aos="zoom-in"
                            src={LandingImg6}
                            alt="Effortlessly Schedule and Run Your Tests"
                            className="img-fluid"
                        />
                    </Col>
                </Row>
            </StandardContent>

            <StandardContent>
                <Row className="align-items-center mt-5">
                    <h2 id="learn-more" className="text-center">
                        Put Artificial Intelligence to Work.
                    </h2>
                    <p className="text-center lead">
                        Quaind exploits state-of-the-art machine-learning models to provide
                        the best assessments of the quality and appearance of your website.
                    </p>
                </Row>
                <Row className="mt-2">
                    <Col sm={4} data-aos="zoom-in" className="pb-2">
                        <Card body>
                            <p className="header lead">
                                Visual Tests That Go Beyond The Simple Image Comparison
                            </p>
                            <p className="description lead">
                                Quaind's advanced visual testing engine goes beyond basic image
                                comparison methods. It employs cutting-edge ML techniques to
                                provide the best recommendations for your website by identifying
                                regions of interest that may be broken.
                            </p>
                            <a href={Url("GetDemo")} class="card-link">
                                Get started
                            </a>
                        </Card>
                    </Col>
                    <Col sm={4} data-aos="zoom-in" className="pb-2">
                        <Card body>
                            <p className="header lead">World-Class Contextual Analysis</p>
                            <p className="description lead">
                                {" "}
                                Quaind automatically identifies grammar problems, detects broken
                                links, and highlights inconsistencies or errors within the text.
                                By analyzing the context in which text appears, Quaind ensures
                                your content is polished, professional, and optimized for both
                                user experience and search engines.
                            </p>
                            <a href={Url("GetDemo")} class="card-link">
                                Get started
                            </a>
                        </Card>
                    </Col>
                    <Col sm={4} data-aos="zoom-in" className="pb-2">
                        <Card body>
                            <p className="header lead">Achieve More with Less Resources</p>
                            <p className="description lead">
                                Let Quaind handle the heavy lifting in quality assurance! Free up your team to focus on tasks that truly need human insight while Quaind takes care of the rest. Boost efficiency, reduce errors, and ensure seamless testing with AI-powered automation.
                                <br />
                                <br />
                            </p>
                            <a href={Url("GetDemo")} class="card-link">
                                Get started
                            </a>
                        </Card>
                    </Col>
                </Row>
                <Row className="align-items-center mt-5">
                    <Col sm={12} className="d-flex justify-content-center">
                        <Button className="btn-lg" variant="primary" href={Url("GetDemo")}>
                            Get started now
                        </Button>
                    </Col>
                </Row>
            </StandardContent>
        </div>
    );
};

export default Landing;
